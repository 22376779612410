/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

/* $.fn.slideFadeToggle  = function(speed, easing, callback) {
	return this.animate({opacity: 'toggle', height: 'toggle'}, speed, easing, callback);
};
*/
(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

				/************ ANIMATION ***********/

				/*   var $svg = $('.perk-inner svg').drawsvg();
				$svg.drawsvg('animate'); */
				/*
				var $svg = $('.three-col svg').drawsvg();
				$svg.drawsvg('animate');

				*/

				/*********** ANIMATION END ********/



				/* Ansök animation */


				document.addEventListener( 'wpcf7submit', function( event ) {

					if(!$('.wpcf7-form').hasClass('invalid')){

						$('.form-container').fadeOut(100);
						setTimeout(function(){
							$('.loader-container').fadeIn(200);
						}, 200);

						setTimeout(function(){
							if($('.wpcf7-form').hasClass('sent')){

								$('.loader-container').fadeOut(200);

								setTimeout(function(){
									$('.success-container').fadeIn(200);
								}, 200);

							}else{
								alert('Någonting blev fel!');
							}

						}, 3000);
					}


				}, false );


				/* Ansök animation END */


				/* Gromodal modal functionality */
				$('document').ready(function () {
					function gromodalToggleGeneral() {
						$('body').toggleClass('modal-open', 200);
						$('.gromodal-overlay').fadeIn(200, "linear");
						$('.gromodal-content-box').fadeToggle(200, "linear");
						$('.gromodal-booking-top').focus(); // Attempt to scroll position to top when on smaller screens.
					}
					function gromodalToggleSpecific(e) {
						var specificModalID,
							theSpecificModal = 'the-';

						specificModalID = e.currentTarget.id !== '' ? e.currentTarget.id : false;

						if(specificModalID !== false){
							theSpecificModal = theSpecificModal + specificModalID;
							$('body').addClass('modal-open', 200);
							$('.gromodal-overlay').fadeIn(200, "linear");
							$('#'+theSpecificModal).fadeIn(200, "linear");
						}else{
							$('body').addClass('modal-open', 200);
							$('.gromodal-overlay').fadeIn(200, "linear");
							$('.gromodal-content-box-specific').fadeIn(200, "linear");
							$('.gromodal-booking-top').focus(); // Attempt to scroll position to top when on smaller screens.
						}
					}
					function gromodalHideGeneral() {
						$('body').removeClass('modal-open', 200);
						$('.gromodal-overlay').fadeOut(200, "linear");
						$('.gromodal-content-box').fadeOut(200, "linear");
						$('.gromodal-content-box-specific').fadeOut(200, "linear");
					}
					function gromodalShowGeneral() {
						$('body').addClass('modal-open', 200);
						$('.gromodal-overlay').fadeIn(200, "linear");
						$('.gromodal-content-box').fadeIn(200, "linear");
						$('.gromodal-booking-top').focus(); // Attempt to scroll position to top when on smaller screens.
					}

					function gromodalToggleAnnouncement(e) {
						var clicked_item = $(e.target);

						if (clicked_item.hasClass('submit-button')) {
							return;
						}

						$('body').toggleClass('modal-open', 200);
						$('.gromodal-announcement-overlay').fadeToggle(200, "linear");
						$('.gromodal-announcement-content-box').fadeToggle(200, "linear");
						$('#gromodal-announcement-booking-top').focus(); // Attempt to scroll position to top when on smaller screens.
					}

					$("#gromodal-fotostudio--btn").on("click", gromodalShowGeneral);
					$("#gromodal-konferens--btn").on("click", gromodalShowGeneral);
					$('.gromodal-exit').on("click", gromodalHideGeneral);
					$('.gromodal-overlay').on("click", gromodalHideGeneral);

					// * Those elements with the class .gromodal-opener NEEDS an id with a naming like this: "gromodal--scale" where scale is the same name as the modal id.
					$('.gromodal-opener').on("click", function(e){
						gromodalToggleSpecific(e);
					});

					$('.announcement-item').on('click', gromodalToggleAnnouncement);
					$('.gromodal-announcement-exit').on("click", gromodalToggleAnnouncement);
					$('.gromodal-announcement-overlay').on("click", gromodalToggleAnnouncement);


					$('.announcement-item').on('click', function (event) {
						var clicked_item = $(event.currentTarget);
						var item = clicked_item.closest('.announcement-item');
						var modal = $('#gromodal-announcement');

						$(modal).find('.subtitle').text(item.data('subTitle'));
						$(modal).find('.title').text(item.data('title'));
						$(modal).find('.p-body').html(item.find('.announcement-description-content').text());
						$(modal).find('.date-human').text(item.data('dateHuman'));
						$(modal).find('.room').css({
							'background-image': 'url(' + item.data('backgroundImage') + ')'
						});
					});
				});


				/***************** MENU FIX ***************************/

				// if($(window).width() > 991){
				// 	$('.menu-burger').hover(function(){
				// 		if(!$('.fw-menu-bg').hasClass('active') && !$(this).hasClass('hover')){
				// 			$('.fw-menu-container').fadeIn(100);
				// 			$('.fw-menu-bg').addClass('active');
				// 			$(this).addClass('active');
				// 			$('.menu-burger').addClass('active');
				// 		}
				// 	});
				// }
				/*
				$('.fw-menu-bg').mouseout(function(){
					if ($('.nav-link:hover').length == 0 &&
					$('.nav-link a:hover').length == 0 &&
					$('.social-items:hover').length == 0 &&
					$('.social-items a:hover').length == 0 &&
					$('.menu-burger:hover').length == 0 &&
					$('.menu-burger svg:hover').length == 0 &&
					$('.social-item:hover').length == 0 &&
					$('.social-item a:hover').length == 0 &&
					$('.fw-menu-bg:hover').length == 0 &&
					$('.fw-menu:hover').length == 0 &&
					$('.fw-menu:hover').length == 0 &&
					$('#menu-full-width-menu:hover').length == 0
				){

					$('.menu-burger').removeClass('active');
					$('.fw-menu-container').fadeOut(100);
					$('.fw-menu-bg').removeClass('active');
				}
				});
				*/

				$('.fw-menu-container').click(function(e){

					var target = $(e.target), article;

					if (target.is('.nav-link') === 0 &&
						target.is('.nav-link a') === 0 &&
						target.is('.social-items') === 0 &&
						target.is('.social-items a') === 0 &&
						target.is('.social-item') === 0 &&
						target.is('.social-item a')  === 0 &&
						target.is('.fw-menu-bg') === 0 &&
						target.is('.fw-menu')  === 0 &&
						target.is('#menu-full-width-menu') === 0
					){
						$('.menu-burger').removeClass('active');
						$('.fw-menu-container').fadeOut(100);
						$('.fw-menu-bg').removeClass('active');
					}
				});

				$('.menu-burger').click(function(){

					$(this).addClass('hover');
					if($(this).hasClass('active')){
						$('.menu-burger').removeClass('active');
						$('.fw-menu-container').fadeOut(100);
						$('.fw-menu-bg').removeClass('active');
						$('.navbar-brand').removeClass('hide-logo');

						if($(window).width() < 992){
							$('.nav-link.ansok').removeClass('dark');
						}

					}else{
						if(!$('.fw-menu-bg').hasClass('active')){
							$('.fw-menu-container').fadeIn(100);
							$('.fw-menu-bg').addClass('active');
							$(this).addClass('active');
							$('.menu-burger').addClass('active');
							$('.navbar-brand').addClass('hide-logo');

							if($(window).width() < 992){
								$('.nav-link.ansok').addClass('dark');
							}
						}
					}
				});
				$('.fw-menu-bg-underlay').click(function(){

					if ($('.menu-burger').hasClass('active')){
						$('.menu-burger').removeClass('active');
						$('.fw-menu-container').fadeOut(100);
						$('.fw-menu-bg').removeClass('active');
						$('.navbar-brand').removeClass('hide-logo');

						if($(window).width() < 992){
							$('.nav-link.ansok').removeClass('dark');
						}

					}else{
						if(!$('.fw-menu-bg').hasClass('active')){
							$('.fw-menu-container').fadeIn(100);
							$('.fw-menu-bg').addClass('active');
							$(this).addClass('active');
							$('.menu-burger').addClass('active');
							$('.navbar-brand').addClass('hide-logo');

							if($(window).width() < 992){
								$('.nav-link.ansok').addClass('dark');
							}
						}
					}
				});

				$('.menu-burger').mouseout(function(){
					$(this).removeClass('hover');
				});

				/***************** MENU FIX END **********************/


				$('documtent').ready(function(){
					setTimeout(function(){
						$('.fade-btn').css('opacity', 1);
					}, 1000);
					setTimeout(function(){
						$('.fade-copy').css('opacity', 0.2);
					}, 500);
				});

				$(function() {
					$("img.lazy").lazyload({
						effect: "fadeIn"
					});
				});

				if($('body').hasClass('page-id-30')){
					$('.toggle-row').click(function(){
						if($(this).hasClass('active')){
							$(this).parent('.col-xs-12').children('.a').slideToggle(200);
							$(this).parent('.col-xs-12').parent('.item').removeClass('active');
							$(this).find('.toggle-q-a').toggleClass('open');
							$(this).toggleClass('active');
						}else{
							$(this).parent('.col-xs-12').children('.a').slideToggle(200);
							$(this).parent('.col-xs-12').parent('.item').addClass('active');
							$(this).find('.toggle-q-a').toggleClass('open');
							$(this).toggleClass('active');
						}
					});
				}

				/*** ***/

				if($(window).width() > 991){
					if ($('.rellax').length) {
						var rellax = new Rellax('.rellax');
					}
				}

				/*** ***/



				function scrollToDiv(element,navheight){
					if(element.context.nodeName === "#document"){
						return; //early if called by form submit
					}
					var offset = element.offset();
					var offsetTop = offset.top;
					var totalScroll = offsetTop-navheight;
					$('body,html').animate({
						scrollTop: totalScroll
					}, 500);
					//	window.location.hash = '';
				}
				var scrollOffset;

				if($(window).width() > 543){
					scrollOffset = 180;
				}else{
					scrollOffset = 100;
				}

				$('.nav-link').click(function(){
					if($(this).hasClass('disabled')){
						return false;
					}else{
						var el = $(this).attr('href');
						if(!$('body').hasClass('home')){
							window.location.href = siteUrl + '/' + el;
						}else{
							var elWrapped = $(el);
							scrollToDiv(elWrapped, scrollOffset);
							return false;
						}
					}
				});


				$('document').ready(function(){
					var hashlink = window.location.hash;

					setTimeout(function(){
						if(hashlink !== '' && hashlink !== '#'){
							var elWrapped = $(hashlink);
							scrollToDiv(elWrapped, scrollOffset);
							window.location.hash = ' ';
						}
					}, 1000);
				});

				if($('body').hasClass('ansok') || $('body').hasClass('ansok-event')){
					$('#ansok-page').css('min-height', $(window).height()+'px');
				}


				var hasBeenTrigged = false;
				$(window).scroll(function() {
					if ($(this).scrollTop() >= 200 && !hasBeenTrigged) { // if scroll is greater/equal then 100 and hasBeenTrigged is set to false.
						if(!$('.mod-navbar-3-container').hasClass('alt')){
							$('.mod-navbar-3-container').addClass('scrolled');
							hasBeenTrigged = true;
						}
					}else{
						if( $(this).scrollTop() <= 200 && hasBeenTrigged){
							if(!$('#collapsingNavbar').hasClass('in')){
								$('.mod-navbar-3-container').removeClass('scrolled');
								hasBeenTrigged = false;
							}
						}
					}
				});
				/*
				if($(window).width() < 992){
					$('.navbar-toggler').click(function(){
						$('.mod-navbar-3-container').addClass('scrolled');
						$(this).toggleClass('open');
					});

					$('.nav-link').click(function(){
						$('.navbar-toggler').trigger('click');
					});

				}

				*/


				/*	function wpcf7submit(){
					$('.wpcf7-submit').width($('.wpcf7-submit').width());
					if($('body').hasClass('ansok')){
						$('.wpcf7-submit').val('TACK FÖR DIN ANSÖKAN');
					}else{
						$('.wpcf7-submit').val('TACK');
					}
					$('.wpcf7-submit').addClass('disabled');
				}


				$('.wpcf7-submit').click(function(){
					$('.wpcf7-submit').width($('.wpcf7-submit').width());
					setTimeout(function(){
						if($('.wpcf7-form').hasClass('sent')){
							wpcf7submit();
						}
					}, 1000);

				}); */


				function validateLogin(){
					var mail = $('#login-mail').val();
					var pw = $('#login-pw').val();
					window.location.replace(templateUrl + '/login-validation.php?mail='+mail+'&pw='+pw);
				}

				$('#login-btn').click(function(){
					validateLogin();
				});

				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
				/*
				$('#las-mer-toggle').click(function(){
					$('.las-mer-content').slideToggle(600);
					if($(this).html('Läs mer')){
						$(this).html('Dölj text');
					}else{
						$(this).html('Läs mer');
					}
				});
				*/

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
